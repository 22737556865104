import { ANIMATION, BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, ListDescription, ListItem, MdSpacing, Row, Tag, useScreenSize } from '@alto/design-system';
import { BackorderedTag, isEligibleForCoupon, useBackorderedMedication } from '@alto/features';
import React from 'react';
import styled from 'styled-components';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { type OriginName } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import { useNavigateToMedDetails } from '~web/hooks/useNavigateToMedDetails';
import IconPill from '~web/images/bluePill.svg';

const PrescriptionInfoContainer = styled.div`
  cursor: pointer;
  padding: ${SPACING.STATIC.MD.px};
  margin-left: -${SPACING.STATIC.MD.px};
  margin-right: -${SPACING.STATIC.MD.px};
  transition: background-color ${ANIMATION.SPEEDS.FAST}ms;
  border-radius: ${BORDERS.RADIUS.LG.px};

  &:hover {
    background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST};
  }
`;

const Img = styled.img`
  width: ${SPACING.STATIC.XL.px};
  height: ${SPACING.STATIC.XL.px};
  border-radius: ${BORDERS.RADIUS.MD.px};
`;

export type Props = {
  readonly byline?: string;
  readonly CTA?: React.ReactElement;
  readonly description?: string; // shows price or view price in cart
  readonly imageURL?: string;
  readonly isCTAButton?: boolean;
  readonly manualCouponEligible?: boolean;
  readonly origin: OriginName;
  readonly prescriptionID?: number;
  readonly title: string;
};

export type Mutable<Type> = {
  -readonly [Key in keyof Type]: Type[Key];
};

export const PrescriptionInfo = ({
  byline,
  CTA,
  description,
  imageURL,
  isCTAButton,
  manualCouponEligible = false,
  origin,
  prescriptionID,
  title,
}: Props) => {
  const { isSMScreenOrBigger } = useScreenSize();

  // TODO: move to BE with coupon model and fetch available coupons query
  // determine coupon eligibility
  const user = useSelectorShared(getCurrentUser);
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const showDiscountTag = manualCouponEligible && isEligibleForCoupon({ ndc: prescription?.ndc, user });

  const navigateToMedDetails = useNavigateToMedDetails();

  const handlePrescriptionInfoClick = () => {
    if (!prescriptionID) return;
    navigateToMedDetails(prescriptionID, origin);
  };

  const isSmallerScreenOrBigger = isSMScreenOrBigger;

  const shouldShowButton = () => {
    if (isCTAButton) return !isSmallerScreenOrBigger;
    return true;
  };

  const showButton = shouldShowButton();

  const isBackorderedMedication = useBackorderedMedication(prescriptionID);

  if (isBackorderedMedication) {
    return (
      <PrescriptionInfoContainer onClick={handlePrescriptionInfoClick}>
        <ListItem
          fullBleed
          title={title}
          byline={byline}
          tagPlacement="bottom"
          tags={[
            <BackorderedTag
              key="backordered-tag"
              origin="Home"
              prescriptionId={prescriptionID}
            />,
          ]}
          LeftContent={<Img src={imageURL || IconPill} />}
        />
      </PrescriptionInfoContainer>
    );
  }

  return (
    <PrescriptionInfoContainer onClick={handlePrescriptionInfoClick}>
      <ListItem
        fullBleed
        title={title}
        byline={byline}
        descriptions={description ? [<ListDescription key={description}>{description}</ListDescription>] : undefined}
        BottomButton={showButton ? CTA : undefined}
        RightContent={
          <Row
            centerVertically
            wrap={false}
          >
            {isCTAButton && isSmallerScreenOrBigger ? (
              <Row wrap={false}>
                {CTA}
                <MdSpacing />
              </Row>
            ) : null}
            <AltoIcon
              name="chevronright"
              type="grey"
            />
          </Row>
        }
        LeftContent={<Img src={imageURL || IconPill} />}
        tags={
          showDiscountTag ? (
            <Tag
              key="discount-tag"
              label="ELIGIBLE FOR DISCOUNT IN CART"
              type="recommended"
            />
          ) : undefined
        }
      />
    </PrescriptionInfoContainer>
  );
};
