// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ActionSheetContext, Body, Button, LandingPage as DesignSystemLandingPage } from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { NavBarLogo } from './NavBarLogo';
import { useQueryLandingPageContent } from '~web/features/info-request/hooks';
import { useStatusBarColor } from '~web/features/info-request/hooks/useStatusBarColor';
import { VerifyActionSheet } from '~web/features/onboarding/components/redesign/VerifyActionSheet';

type Props = {
  params: {
    externalUUID: string;
    hubReferralID: string;
  };
};

const LandingPageComponent = (props: Props) => {
  const { externalUUID, hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const { trackPageView } = useAnalytics();
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);

  const { isFetching, headerLogoUrl, welcomeHeader, welcomeBody, welcomeImageUrl, nextURL } =
    useQueryLandingPageContent({
      externalUUID,
      hubReferralID,
    });

  const illustrationSrc = welcomeImageUrl ? { uri: welcomeImageUrl } : undefined;

  const handleGetStarted = () => {
    setActiveActionSheet(
      <VerifyActionSheet
        useExternalVerification
        externalUUID={externalUUID}
        hubReferralID={hubReferralID}
        onVerifySuccess={closeActionSheet}
        nextURL={nextURL}
      />,
    );
  };

  useStatusBarColor(COLORS.BACKGROUND_COLORS.GREY_LIGHTEST);

  useEffect(() => {
    trackPageView({
      event: EVENTS.INFO_REQUEST__LANDING_PAGE_VIEWED,
      params: { hubReferralID },
    });
  }, [trackPageView, hubReferralID]);

  if (isFetching || !welcomeHeader) {
    return null;
  }

  return (
    <DesignSystemLandingPage
      backgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      footerPlacementContext="webScreenWithoutPolicyFooter"
      withoutWebFramingElementHeights
      NavBarCenterContent={headerLogoUrl ? <NavBarLogo illustrationSrc={headerLogoUrl} /> : undefined}
      illustrationSrc={illustrationSrc}
      title={welcomeHeader}
      buttons={[
        <Button
          label="Get started"
          key="get-started"
          onPress={handleGetStarted}
        />,
      ]}
    >
      {welcomeBody ? <Body center>{welcomeBody}</Body> : null}
    </DesignSystemLandingPage>
  );
};

export const LandingPage = withRouter(LandingPageComponent);
