import { Toast, ToastContext } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import {
  DeprecatedModal,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  InputUploadImage,
  Label,
  LoadingButton,
  Text,
  createValidator,
  spacing,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldInputProps, type FieldProps, Form, Formik, type FormikProps } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { closeModal } from '~shared/actions/modal';
import { clearUserError, uploadPhotoIDs } from '~shared/actions/users';
import { useCartUsers } from '~shared/features/cart/hooks/useCartUsers';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getDisplayFullName } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type User } from '~shared/types';

const BodyText = styled(Text)`
  margin-bottom: ${spacing.mdSpacing};
`;

export type FormValues = Record<string, string>;

const PhotoIDUpload = () => {
  const { trackPageView } = useAnalytics();
  const { usersWhoNeedPhotoID: users } = useCartUsers();
  const dispatch = useDispatchShared();
  const { uploadPhotoIDsLoading: loading } = useSelectorShared((state) => getLoading(state));
  const show = useSelectorShared((state) => state.modal.show);
  const modalType = useSelectorShared((state) => state.modal.modalType);
  const { addToast } = useContext(ToastContext);
  const { trackEvent } = useAnalytics();
  const isShowing = show && modalType === 'PHOTO_ID_UPLOAD_MODAL';

  const dismissModal = useCallback(() => {
    dispatch(closeModal('PHOTO_ID_UPLOAD_MODAL'));
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (data: FormValues) => {
      const payload = Object.keys(data).reduce((obj, userID) => {
        const files = data[userID];
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
        obj[userID] = files[0];
        return obj;
      }, {});
      const success = await dispatch(uploadPhotoIDs(payload));
      if (success) {
        trackEvent({ event: EVENTS.UPLOAD_ID_SUCCEEDED });
        addToast(<Toast variant="success">Your photo ID has been uploaded.</Toast>);
        dismissModal();
        dispatch(clearUserError());
        const route = window.location.pathname;
        if (route !== '/checkout') {
          dispatch(push('/checkout'));
        }
      } else {
        addToast(<Toast variant="error">Please try again or message support if the problem persists.</Toast>);
        trackEvent({ event: EVENTS.UPLOAD_ID_FAILED });
      }
    },
    [addToast, dismissModal, dispatch, trackEvent],
  );

  useEffect(() => {
    trackPageView({
      event: EVENTS.UPLOAD_ID_VIEWED,
    });
  }, [trackPageView]);

  const onChangePhotoInput = (field: FieldInputProps<any>, form: FormikProps<any>) => {
    return (files: File[] | null | undefined) => {
      form.setFieldValue(field.name, files);
    };
  };

  const generateInitialValues = (users: User[]): FormValues => {
    const initialValues = {};
    users.forEach((user) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
      initialValues[user.id.toString()] = null;
    });
    return initialValues;
  };

  const generateValidator = (users: User[]) => {
    const fields: Record<string, (value: string) => string | null> = {};
    users.forEach((user) => {
      fields[user.id.toString()] = (value: string) => {
        return !value ? 'Please upload a photo ID.' : null;
      };
    });
    return fields;
  };

  if (!isShowing) return null;

  return (
    <DeprecatedModal
      showing
      onClose={dismissModal}
      title="ID Required"
    >
      <Formik
        initialValues={generateInitialValues(users)}
        validate={createValidator(generateValidator(users))}
        validateOnBlur={false}
        validateOnChange
        onSubmit={handleSubmit}
      >
        {/* @ts-expect-error TS(2322): Type '{ children: Element[]; submitting: boolean; }' is not assignable to type 'IntrinsicAttributes ... (Delete me to see the full error) */}
        <Form submitting={loading}>
          <DeprecatedModalBody>
            <BodyText>One or more medications in your order requires a photo ID.</BodyText>
            <div>
              {users.map((user) => {
                return (
                  <div key={user.id}>
                    <Label>{user ? getDisplayFullName(user) : 'Photo ID'}*</Label>
                    {user?.minor ? (
                      <div>
                        <Text>
                          Since {user.preferred_first_name || user.first_name} is under 18 and may not have an ID,
                          please feel free to upload a parent or guardian&#39;s ID instead.
                        </Text>
                      </div>
                    ) : null}

                    <Field name={user.id.toString()}>
                      {({ field, meta, form }: FieldProps<string, FormValues>) => {
                        return (
                          <div>
                            <InputUploadImage
                              input={field}
                              meta={meta}
                              value={field.value}
                              onChange={onChangePhotoInput(field, form)}
                              id="photo-id-upload"
                              uploadText="+ Add photo ID"
                              required
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                );
              })}
            </div>
          </DeprecatedModalBody>
          <DeprecatedModalFooter>
            <LoadingButton
              type="submit"
              loading={loading}
            >
              Proceed to Checkout
            </LoadingButton>
          </DeprecatedModalFooter>
        </Form>
      </Formik>
    </DeprecatedModal>
  );
};

export default PhotoIDUpload;
