// @owners { team: patients-team }
import { Button, Column, InputSelect, InputText, MdSpacing, Toast, ToastContext } from '@alto/design-system';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { push } from 'react-router-redux';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import { useSignPatientAuthorization } from '~web/features/info-request/hooks';

type Props = {
  readonly buttonLabel: string;
  readonly hubReferralID: number;
  readonly onClose: () => void;
  readonly nextURL?: string;
};

type PatientAuthorizationValues = {
  signerRelationship: string;
  fullName: string;
};

const signerRelationshipOptions = [
  { value: 'Self', label: 'Self' },
  { value: 'Spouse/partner', label: 'Spouse/partner' },
  { value: 'Parent', label: 'Parent' },
  { value: 'Other', label: 'Other' },
];

export const PatientAuthorizationForm = ({ buttonLabel, hubReferralID, onClose, nextURL }: Props) => {
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatchShared();
  const { mutateAsync: signPatientAuthorization } = useSignPatientAuthorization();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    clearErrors,
    setValue,
  } = useForm<PatientAuthorizationValues>({
    defaultValues: {
      signerRelationship: 'Self',
    },
  });

  const onSubmit = async (formValues: PatientAuthorizationValues) => {
    trackEvent({
      event: EVENTS.INFO_REQUEST__SUBMIT_ELECTRONIC_SIGNATURE_CLICKED,
      params: { hubReferralID },
    });

    const params = {
      signer_relationship: formValues.signerRelationship,
      hub_referral_id: hubReferralID,
    };

    const response = await signPatientAuthorization(params);

    if (response.errors) {
      addToast(<Toast variant="error">Failed to sign the patient authorization. Please try again.</Toast>);
    } else {
      addToast(<Toast variant="success">Thanks for signing your patient authorization form.</Toast>);
      if (nextURL) {
        dispatch(push(nextURL));
      }
    }
    reset();
    onClose();
  };

  return (
    <Column>
      <InputSelect
        label="Relationship to Patient"
        {...register('signerRelationship')}
        defaultValue="Self"
        onValueChange={(value) => {
          setValue('signerRelationship', value);
          clearErrors('signerRelationship');
        }}
        options={signerRelationshipOptions}
        testID="signer-relationship-select"
      />
      <MdSpacing />
      <InputText
        label="Full Name"
        {...register('fullName', {
          required: 'Full name is required',
        })}
        onChangeText={(value) => {
          setValue('fullName', value);
          clearErrors('fullName');
        }}
        error={errors.fullName?.message}
        required
        accessibilityLabel="full-name-input"
        testID="full-name-input"
        caption="By typing and submitting your name, you are agreeing to this as the electronic representation of your signature on this document."
      />
      <MdSpacing />
      <Button
        testID="sign-patient-authorization-button"
        label={buttonLabel}
        loading={isSubmitting}
        onPress={handleSubmit(onSubmit)}
      />
    </Column>
  );
};
