// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, SmSpacing } from '@alto/design-system';
import React from 'react';

type Props = {
  description: string;
  details: string;
};

export const CouponDetailsActionSheet = ({ description, details }: Props) => {
  return (
    <ActionSheetV2 title="How it works">
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body fontFamily="bold">{description}</Body>
        <SmSpacing />
        <Body>{details}</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
