// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { TEXT_COLORS } from '@alto/design-library-tokens/src/colors/textColors';
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  Description,
  InlineAlert,
  InputCheckbox,
  LgPadding,
  Link,
  MdSpacing,
  Toast,
  ToastContext,
  XsSpacing,
} from '@alto/design-system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { type WebViewMessageEvent } from 'react-native-webview';
// eslint-disable-next-line import/no-deprecated
import { applyManualCoupon } from '~shared/actions/manualCoupons';
import { getNextDeliveryForPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { insurances } from '~shared/queries/query-keys/insurances';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { WebView } from '../../shared/components/WebView';
import { wrapHtmlContent } from '../helpers/wrapHtmlContent';
import { useGetCouponSignUpConfig } from '../queries/queries';
import { useAddCopayCard } from '../queries/useAddCopayCard';
import { CouponDetailsActionSheet } from './CouponDetailsActionSheet';

type Props = {
  autoAcceptTerms?: boolean; // only used for tests so patient confirms having read terms and conditions
  couponID: number;
  prescription: Prescription;
};

const ACCEPT_TERMS_COPY = 'By checking this box, I confirm that I meet the defined requirements above.';
const REVIEW_TERMS_ERROR =
  'Before adding the copay card please review all the terms and conditions and then check the confirmation box to agree.';
const ADD_COPAY_CARD_ERROR = 'Please try again or contact support if the problem persists.';
const MISSING_COPAY_CARD_PARAMS_ERROR = 'Some information is missing. Please contact support if the problem persists.';
const WEB_VIEW_MOBILE_HEIGHT = 350;
const WEB_VIEW_WEB_HEIGHT = 500;
export const SCROLLED_TO_BOTTOM = 'scrolled_to_bottom';

export const CouponAttestationActionSheet = ({ autoAcceptTerms, couponID, prescription }: Props) => {
  const isWeb = Platform.OS === 'web';
  const dispatch = useDispatchShared();
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(autoAcceptTerms || false);
  const [isAcceptTermsDisabled, setIsAcceptTermsDisabled] = useState(true);
  const [addCouponError, setAddCouponError] = useState('');
  const { mutateAsync: addCopayCard } = useAddCopayCard();
  const delivery = useSelectorShared((state) =>
    getNextDeliveryForPrescriptionID(state, {
      prescriptionID: prescription.id,
    }),
  );
  const { couponConfig, error: couponSignUpConfigError } = useGetCouponSignUpConfig(couponID);
  const showHowItWorksLink = couponConfig?.offer_description || couponConfig?.offer_details;

  // custom hook to pass message when patient scrolls to bottom of term and conditions in WebView
  useEffect(() => {
    if (!isWeb) return;

    const messageHandler = (event: any) => {
      handleWebViewMessage(event);
    };
    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [isWeb]);

  const handleHowItWorksPress = () => {
    // won't occur, how it works link gated by presence of couponConfig in render method
    if (!showHowItWorksLink) return;

    setActiveActionSheet(
      <CouponDetailsActionSheet
        description={couponConfig.offer_description}
        details={couponConfig.offer_details}
      />,
    );
  };

  const handleAddCoupon = async () => {
    if (!hasAcceptedTerms) {
      setAddCouponError(REVIEW_TERMS_ERROR);
      return;
    }

    const { ndc: prescriptionNdc, id: prescriptionID, user_id: userID } = prescription;
    const deliveryID = delivery?.id;

    if (!userID || !prescriptionNdc || !deliveryID) {
      setAddCouponError(MISSING_COPAY_CARD_PARAMS_ERROR);
      return;
    }

    trackEvent({
      event: EVENTS.COPAY_COUPON__ADD_COUPON_PRESSED,
      params: { couponID },
    });

    const params = {
      user_id: userID,
      coupon_id: couponID,
      prescription_id: prescriptionID,
      prescription_ndc: prescriptionNdc,
      terms_agreed_to: hasAcceptedTerms,
    };
    const { insurance } = await addCopayCard({ params });

    if (!insurance) {
      setAddCouponError(ADD_COPAY_CARD_ERROR);
      return;
    }

    // eslint-disable-next-line import/no-deprecated
    const appliedCoupon = await dispatch(applyManualCoupon(deliveryID, insurance.id, prescriptionID));
    if (!appliedCoupon) {
      setAddCouponError(ADD_COPAY_CARD_ERROR);
    } else {
      trackEvent({
        event: EVENTS.COPAY_COUPON__APPLIED_DISCOUNT,
        params: {
          couponID,
        },
      });
      queryClient.invalidateQueries({ queryKey: insurances.fetchAll._def });
      closeActionSheet();
      addToast(<Toast>Copay card added. You can view your copay card in your Alto profile.</Toast>);
    }
  };

  const handleWebViewMessage = (event: WebViewMessageEvent) => {
    // @ts-expect-error event.data is correct for web platform
    const message = Platform.OS === 'web' ? event.data : event.nativeEvent.data;
    if (message === SCROLLED_TO_BOTTOM) {
      setIsAcceptTermsDisabled(false);
    }
  };

  return (
    <ActionSheetV2
      title={`Get ${prescription.medication_name || 'your medication'} for as little as $0* by adding a copay card`}
      analyticsName="coupon_attestation"
      buttons={[
        <Button
          key="add-coupon-button"
          label="Apply instant discount"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleAddCoupon}
        />,
        <Button
          key="cancel-coupon-button"
          label="Cancel"
          onPress={closeActionSheet}
          type="tertiary"
        />,
      ]}
      checkbox={
        <InputCheckbox
          checkPlacement="left"
          label=""
          name="acceptCopayCardTerms"
          descriptions={
            <Description color={isAcceptTermsDisabled ? TEXT_COLORS.DISABLED : TEXT_COLORS.GREY}>
              {couponConfig?.terms_checkbox_text ?? ACCEPT_TERMS_COPY}
            </Description>
          }
          fullBleed
          onPress={() => {
            setHasAcceptedTerms(!hasAcceptedTerms);
            setAddCouponError('');
          }}
          noHighlight
          value={hasAcceptedTerms}
          testID="accept-copay-card-terms"
          disabled={isAcceptTermsDisabled}
        />
      }
      footnote={
        <>
          {addCouponError ? (
            <>
              <InlineAlert type="error">
                <Description fontFamily="bold">Could not add copay card</Description>
                <XsSpacing />
                <Description>
                  Before adding the copay card please review all the terms and conditions and then check the
                  confirmation box to agree.
                </Description>
              </InlineAlert>
              <MdSpacing />
            </>
          ) : undefined}
          <Description
            fontFamily="semibold"
            color={COLORS.TEXT_COLORS.GREY}
          >
            Please review all the terms and conditions before confirming you meet the requirements below.
          </Description>
        </>
      }
      fullscreen
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {showHowItWorksLink ? (
          <>
            <Link onPress={handleHowItWorksPress}>How it works</Link>
            <XsSpacing />
          </>
        ) : null}
        {couponSignUpConfigError ? (
          <InlineAlert type="error">
            <Description fontFamily="bold">Terms and conditions are not available</Description>
            <XsSpacing />
            <Description>Please contact support if the problem persists.</Description>
          </InlineAlert>
        ) : (
          <WebView
            onMessage={!isWeb ? handleWebViewMessage : undefined}
            originWhitelist={['*']}
            source={{ html: wrapHtmlContent(couponConfig?.terms || '') }}
            style={{ height: isWeb ? WEB_VIEW_WEB_HEIGHT : WEB_VIEW_MOBILE_HEIGHT }}
          />
        )}
      </LgPadding>
    </ActionSheetV2>
  );
};
