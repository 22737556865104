// @owners { team: patients-team }
import { differenceInYears, isAfter, isValid, parse } from 'date-fns';
import { DATE_FORMATS } from '~shared/helpers/date';
import { type User } from '~shared/types';

const getAge = (date: string) => {
  const parsedDate = parse(date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const now = new Date();

  if (isValid(parsedDate) && isAfter(now, parsedDate)) {
    return differenceInYears(now, parsedDate);
  }

  return undefined;
};

const paxlovidPolicy = (user: User) => {
  if (!user.date_of_birth) return false;

  const age = getAge(user.date_of_birth);
  return age ? 12 <= age && age <= 65 : false;
};

const nurtecOdtPolicy = (user: User) => {
  if (!user.date_of_birth) return false;

  const age = getAge(user.date_of_birth);
  return age ? age >= 18 : false;
};

type CouponPolicy = (user: User) => boolean;

const COUPON_POLICIES: Record<string, CouponPolicy> = {
  '00069532130': paxlovidPolicy,
  '00069531720': paxlovidPolicy,
  '72618300002': nurtecOdtPolicy,
};

export const isEligibleForCoupon = ({ ndc, user }: { ndc?: string; user?: User }) => {
  if (!ndc || !user) return false;

  const policy = COUPON_POLICIES[ndc];

  if (!policy) return false;

  return policy(user);
};
