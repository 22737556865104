import { type AvailableShipmentFee } from '@alto/scriptdash/alto/orders/types/v1/available_shipment_fee';
import { CheckoutFlowTypeMap } from '@alto/scriptdash/alto/patient_app/checkout_flow/types/v1/checkout_flow_type';
import {
  type EnterCartData,
  type EnterCheckoutData,
  type FetchEnterCartRequest,
  type FetchEnterCartResponse,
} from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import { type FetchAvailableResponseData as FetchAvailableDeliveryWindowsResponseData } from '@alto/scriptdash/alto/patient_app/scheduling/v1/delivery_windows_endpoint';
import { EventTypes } from 'redux-segment';
import { openModal } from './modal';
import { ACTION_SHEET_CART_UPDATED, showActionSheet } from './ui/actionSheet';
import { type BillingOverridePayload } from '~shared/actions/prescriptions';
import { getAddress } from '~shared/features/addresses/selectors/getAddress';
import getCart from '~shared/features/checkout/selectors/getCart';
import { getItemsInOrder__DEPRECATED } from '~shared/features/checkout/selectors/getItems';
import { getOrder, getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { type Item, type ItemKey, type Order } from '~shared/features/checkout/types';
import { getIsPrescriptionScheduleable } from '~shared/features/prescriptions/selectors/getScheduleableForPrescriptionID';
import { getAppliedShipmentFeesForOrder } from '~shared/features/shipment-fees/selectors/getAppliedShipmentFeesForOrder';
import { getIsEditingExistingOrder, getIsSplitPaymentEnabled } from '~shared/features/ui/selectors/getCart';
import { get, post, put } from '~shared/helpers/apiHelper';
import { isNative } from '~shared/helpers/isNative';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { getIsUnmodifiedProgynyWizardFlow } from '~shared/selectors/checkout/progyny';
import {
  type Prescription,
  type PricingResultPaymentType,
  type ReduxDispatch,
  type ReduxGetStateShared,
} from '~shared/types';
import { type APIError } from '~shared/types/APIError';

type AddToCartPayload = {
  resource_type: 'Prescription' | 'Delivery';
  resource_id: number;
  resetOrderDate?: boolean;
  billing_overrides?: BillingOverridePayload;
  server_side_cart_origin?: boolean;
};

export const ADD_TO_CART = 'ADD_TO_CART';
export type ActionAddToCart = {
  type: typeof ADD_TO_CART;
  payload: AddToCartPayload;
};

// @deprecated - use libs/shared/features/cart/hooks/useAddToCart hook
export function addToCart__DEPRECATED(
  item: ItemKey,
  resetOrderDate?: boolean,
  billing_overrides?: BillingOverridePayload,
  server_side_cart_origin?: boolean,
): ActionAddToCart {
  return {
    type: ADD_TO_CART,
    payload: {
      resetOrderDate,
      billing_overrides,
      server_side_cart_origin,
      ...item,
    },
  };
}

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export type RemoveFromCartPayload = {
  resource_type: 'Prescription' | 'Delivery';
  resource_id: number;
  prescription?: Prescription;
  server_side_cart_origin?: boolean;
};
export type ActionRemoveFromCart = {
  type: typeof REMOVE_FROM_CART;
  payload: RemoveFromCartPayload;
  properties?: Record<string, any> | null | undefined;
};

export function removeFromCart(
  item: RemoveFromCartPayload,
  properties?: Record<string, any> | null,
  server_side_cart_origin?: boolean,
): ActionRemoveFromCart {
  return {
    type: REMOVE_FROM_CART,
    payload: {
      server_side_cart_origin,
      ...item,
    },
    properties,
  };
}

export const UPDATE_CART = 'UPDATE_CART';
export type ActionUpdateCart = {
  type: typeof UPDATE_CART;
  payload: UpdateOrderPayload;
};

export const updateCart = (payload: UpdateOrderPayload): ActionUpdateCart => {
  return { type: UPDATE_CART, payload };
};

export const UPDATE_EDIT_ORDER = 'UPDATE_EDIT_ORDER';
export type ActionUpdateEditOrder = {
  type: typeof UPDATE_EDIT_ORDER;
  payload: UpdateOrderPayload;
};

export const updateEditOrder = (payload: UpdateOrderPayload): ActionUpdateEditOrder => {
  return {
    type: UPDATE_EDIT_ORDER,
    payload,
  };
};

type UpdateOrderPayload = {
  address_id?: Order['address_id'];
  date?: Order['date'];
  deliver_after?: Order['deliver_after'];
  deliver_before?: Order['deliver_before'];
  home_to_sign_confirmation?: Order['home_to_sign_confirmation'];
  include_sharps_container?: Order['include_sharps_container'];
  origin?: string;
  payment_method_id?: Order['payment_method_id'];
  payment_method_amount?: Order['payment_method_amount'];
  second_payment_method_id?: Order['second_payment_method_id'];
  second_payment_method_amount?: Order['second_payment_method_amount'];
  tip_amount?: Order['tip_amount'];
  tip_payment_method_id?: Order['tip_payment_method_id'];
  delivery_method?: Order['delivery_method'];
  pay_at_pickup?: Order['pay_at_pickup'];
  pickup_address?: Order['pickup_address'];
  pickup_ready_time?: Order['pickup_ready_time'];
  is_asap?: Order['is_asap'];
};

// We can't use the Order type here as the payload directly, because I'm assuming UpdateOrderPayload purposefully
// indicates keys as null to make sure the reducer clears out the value. If the payload doesn't have the key to begin with
// the reducer will keep the original value.
export function updateOrder(order: UpdateOrderPayload) {
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    const isEditingOrder = getIsEditingExistingOrder(state);
    const { second_payment_method_id } = isEditingOrder ? state.cart.editOrder : state.cart.cart;

    if (second_payment_method_id && order.payment_method_id === second_payment_method_id) {
      order.second_payment_method_id = undefined;
    }

    if (order.address_id) {
      const currentAddress = getOrderAddress(state);
      const newAddress = getAddress(state, {
        addressID: order.address_id,
      });

      if (currentAddress?.courier_tips_available && !newAddress?.courier_tips_available) {
        // clear the tip if the delivery address no longer supports courier tips
        order.tip_amount = 0;
      }
    }

    if (isEditingOrder) {
      dispatch(updateEditOrder(order));
    } else {
      dispatch(updateCart(order));
    }
  };
}

type RemoveAutoRefillsPayload = {
  prescriptionIDs: number[];
};

export const REMOVE_AUTO_REFILLS = 'REMOVE_AUTO_REFILLS';
export type ActionRemoveAutoRefills = {
  type: typeof REMOVE_AUTO_REFILLS;
  payload: RemoveAutoRefillsPayload;
};

export function removeAutoRefills__DEPRECATED(prescriptionIDs: number[]): ActionRemoveAutoRefills {
  return {
    type: REMOVE_AUTO_REFILLS,
    payload: {
      prescriptionIDs,
    },
  };
}

type UpdateCartAutoRefillPayload = {
  prescriptionID: number;
  newAutoRefillStatus: boolean;
};

export const UPDATE_CART_AUTO_REFILL = 'UPDATE_CART_AUTO_REFILL';
export type ActionUpdateCartAutoRefill = {
  type: typeof UPDATE_CART_AUTO_REFILL;
  payload: UpdateCartAutoRefillPayload;
};

/**
 * @deprecated use libs/shared/features/cart/hooks/useUpdateCartItem hook
 */
export function updateCartAutoRefill__DEPRECATED(
  prescriptionID: number,
  newAutoRefillStatus: boolean,
): ActionUpdateCartAutoRefill {
  return {
    type: UPDATE_CART_AUTO_REFILL,
    payload: {
      prescriptionID,
      newAutoRefillStatus,
    },
  };
}

export const ATTEMPTED_ORDER_UPDATE = 'ATTEMPTED_ORDER_UPDATE';
export type ActionAttemptedOrderUpdate = {
  type: typeof ATTEMPTED_ORDER_UPDATE;
};

function attemptedOrderUpdate(): ActionAttemptedOrderUpdate {
  return {
    type: ATTEMPTED_ORDER_UPDATE,
  };
}

export const INIT_EXISTING_ORDER = 'INIT_EXISTING_ORDER';
export type ActionInitExistingOrder = {
  type: typeof INIT_EXISTING_ORDER;
  payload: Record<string, any>;
};

export function initializeExistingOrder(orderWithShipmentID: {
  order: Order;
  shipmentID: number;
}): ActionInitExistingOrder {
  return {
    type: INIT_EXISTING_ORDER,
    payload: orderWithShipmentID,
  };
}

export const INIT_SNOOZE_ORDER = 'INIT_SNOOZE_ORDER';
export type ActionInitSnoozeOrder = {
  type: typeof INIT_SNOOZE_ORDER;
};

export function initializeSnoozeOrder(): ActionInitSnoozeOrder {
  return {
    type: INIT_SNOOZE_ORDER,
  };
}

export const EXIT_EDITING_ORDER = 'EXIT_EDITING_ORDER';
export type ActionExitEditingOrder = {
  type: typeof EXIT_EDITING_ORDER;
};

export function exitEditingOrder(): ActionExitEditingOrder {
  return {
    type: EXIT_EDITING_ORDER,
  };
}

export const TOGGLE_SPLIT_PAYMENT = 'TOGGLE_SPLIT_PAYMENT';
export type ActionToggleSplitPayment = {
  type: typeof TOGGLE_SPLIT_PAYMENT;
};

export function toggleSplitPayment(): ActionToggleSplitPayment {
  return {
    type: TOGGLE_SPLIT_PAYMENT,
  };
}

export const TURN_ON_SPLIT_PAYMENT = 'TURN_ON_SPLIT_PAYMENT';
export type ActionTurnOnSplitPayment = {
  type: typeof TURN_ON_SPLIT_PAYMENT;
};

export function turnOnSplitPayment(): ActionTurnOnSplitPayment {
  return {
    type: TURN_ON_SPLIT_PAYMENT,
  };
}

export const CLEAR_CART = 'CLEAR_CART';
export type ActionClearCart = {
  type: typeof CLEAR_CART;
};

export function clearCart(): ActionClearCart {
  return {
    type: CLEAR_CART,
  };
}

export const CLEAR_EDIT_ORDER = 'CLEAR_EDIT_ORDER';
export type ActionClearEditOrder = {
  type: typeof CLEAR_EDIT_ORDER;
};

export function clearEditOrder(): ActionClearEditOrder {
  return {
    type: CLEAR_EDIT_ORDER,
  };
}

export const SAVE_ORDER_LOADING = 'SAVE_ORDER_LOADING';
export type ActionSavingOrder = {
  type: typeof SAVE_ORDER_LOADING;
};

function savingOrder(): ActionSavingOrder {
  return {
    type: SAVE_ORDER_LOADING,
  };
}

export const SAVE_ORDER_FAILED = 'SAVE_ORDER_FAILED';
export type ActionSaveOrderFailed = {
  type: typeof SAVE_ORDER_FAILED;
  payload: APIError;
};

export function saveOrderFailed(error: APIError): ActionSaveOrderFailed {
  return {
    type: SAVE_ORDER_FAILED,
    payload: error,
  };
}

export const SAVE_ORDER_SUCCEEDED = 'SAVE_ORDER_SUCCEEDED';
export type ActionSaveOrderSucceeded = {
  type: typeof SAVE_ORDER_SUCCEEDED;
};

export function saveOrderSucceeded(): ActionSaveOrderSucceeded {
  return {
    type: SAVE_ORDER_SUCCEEDED,
  };
}

export const CLEAR_SAVE_ORDER_ERROR = 'CLEAR_SAVE_ORDER_ERROR';
export type ActionClearSaveOrderError = {
  type: typeof CLEAR_SAVE_ORDER_ERROR;
};

export function clearSaveOrderError(): ActionClearSaveOrderError {
  return {
    type: CLEAR_SAVE_ORDER_ERROR,
  };
}

export const SHOW_VALIDATION_ERRORS = 'SHOW_VALIDATION_ERRORS';
export type ActionShowValidationErrors = {
  type: typeof SHOW_VALIDATION_ERRORS;
};

export function showValidationErrors(): ActionShowValidationErrors {
  return {
    type: SHOW_VALIDATION_ERRORS,
  };
}

export const SET_ORDER_AT_INDEX = 'SET_ORDER_AT_INDEX';
export type ActionSetOrderAtIndex = {
  type: typeof SET_ORDER_AT_INDEX;
  payload: {
    orderIndex: number;
    order: Order;
  };
};

export function setOrderAtIndex(payload: ActionSetOrderAtIndex['payload']): ActionSetOrderAtIndex {
  return {
    type: SET_ORDER_AT_INDEX,
    payload,
  };
}

export const SET_ORDER_INDEX = 'SET_ORDER_INDEX';
export type ActionSetOrderIndex = {
  type: typeof SET_ORDER_INDEX;
  payload: number;
};

export function setOrderIndex(orderIndex: number): ActionSetOrderIndex {
  return {
    type: SET_ORDER_INDEX,
    payload: orderIndex,
  };
}

export const RESET_ORDERS_BY_INDEX = 'RESET_ORDERS_BY_INDEX';
export type ActionResetOrdersByIndex = {
  type: typeof RESET_ORDERS_BY_INDEX;
};
export function resetOrdersByIndex(): ActionResetOrdersByIndex {
  return {
    type: RESET_ORDERS_BY_INDEX,
  };
}

const V3_API_VERSION = 'v3';

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function saveOrder() {
  return async (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    dispatch(savingOrder());
    dispatch(attemptedOrderUpdate());

    const state = getState();
    const isEditing = getIsEditingExistingOrder(state);
    const isSplitPaymentEnabled = getIsSplitPaymentEnabled(state);
    const order = getOrder(state);
    const scheduledWithProgynyWizard = getIsUnmodifiedProgynyWizardFlow(state);
    const items = getItemsInOrder__DEPRECATED(state);
    const appliedShipmentFees = getAppliedShipmentFeesForOrder(state);

    if (!isSplitPaymentEnabled) {
      order.second_payment_method_id = undefined;
      order.payment_method_amount = undefined;
      order.second_payment_method_amount = undefined;
    }

    const ordersByIndex = getOrderIndex(state);
    const isSplitShipments = ordersByIndex > 0;
    // for split shipments, we don't want to force the FedEx delivery method to be courier
    // passing undefined will allow the backend to determine the delivery method
    if (isSplitShipments) {
      order.delivery_method = undefined;
    }

    order.applied_shipment_fees = appliedShipmentFees;

    // scheduled_with_progyny_wizard means that the order was entirely scheduled by
    // going through the med management, and no changes were made afterward
    order.scheduled_with_progyny_wizard = scheduledWithProgynyWizard;

    //reorganize pay at pickup value
    order.pay_at_pickup = order.delivery_method === 'pickup' ? order.pay_at_pickup : false;

    const deliveries = items.map((item) => item.apiPayload());
    const data = {
      order,
      deliveries,
    };

    const method = isEditing ? put : post;

    // schedule_shipment
    return await method('/deliveries', data, {
      version: V3_API_VERSION,
    });
  };
}

export function fetchWindows() {
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    const { date, address_id } = getOrder(state);
    const items = getItemsInOrder__DEPRECATED(state);
    // use the Item interface because this call can happen when editing an order, which has DeliveryItem's
    const prescription_ids: number[] = items.map((item: Item) => item.prescription.id);
    // @ts-expect-error TS doesn't recognize that filter(Boolean) will filter out the undefined's
    const delivery_ids: number[] = items.map((item: Item) => item.delivery?.id).filter(Boolean);

    dispatch(fetchDeliveryWindows(address_id, date, prescription_ids, delivery_ids));
  };
}

export const SELECT_PAYMENT_TYPE = 'SELECT_PAYMENT_TYPE';
export type ActionSelectPaymentType = {
  type: typeof SELECT_PAYMENT_TYPE;
  payload: {
    prescriptionID: number;
    paymentType: PricingResultPaymentType;
  };
};

/**
 * @deprecated use libs/shared/features/cart/hooks/useUpdateCartItem hook
 */
export function selectPaymentType__DEPRECATED(
  prescriptionID: number,
  paymentType: PricingResultPaymentType,
): ActionSelectPaymentType {
  return {
    type: SELECT_PAYMENT_TYPE,
    payload: {
      prescriptionID,
      paymentType,
    },
  };
}

export function removeUnschedulablePrescriptionsFromCart() {
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    const { items } = getCart(state);

    if (!items) return;

    let removedItems = false;

    items.forEach((item: ItemKey) => {
      if (item.resource_type !== 'Prescription') {
        return;
      }

      const prescriptionID = item.resource_id;

      const scheduleable = getIsPrescriptionScheduleable(state, {
        prescriptionID,
      });

      if (!scheduleable) {
        removedItems = true;
        dispatch(
          sendAnalyticsEvent(
            createEvent(EVENTS.CART__UNSCHEDULABLE_PRESCRIPTION_REMOVED, {}, { prescriptionId: prescriptionID }),
          ),
        );
        dispatch(
          removeFromCart({
            resource_type: 'Prescription',
            resource_id: prescriptionID,
          }),
        );
      }
    });

    if (removedItems) {
      if (isNative) {
        dispatch(showActionSheet({ key: ACTION_SHEET_CART_UPDATED }));
      } else {
        dispatch(openModal('UPDATED_CART_MODAL'));
      }
    }
  };
}

export const FETCH_ENTER_CHECKOUT_SUCCEEDED = 'FETCH_ENTER_CHECKOUT_SUCCEEDED';
export type ActionFetchEnterCheckoutSucceeded = {
  type: typeof FETCH_ENTER_CHECKOUT_SUCCEEDED;
  payload: EnterCheckoutData;
};

export function fetchEnterCheckoutSucceeded(payload: EnterCheckoutData): ActionFetchEnterCheckoutSucceeded {
  return {
    type: FETCH_ENTER_CHECKOUT_SUCCEEDED,
    payload,
  };
}

export const CLEAR_FETCH_ENTER_CHECKOUT_ERROR = 'CLEAR_FETCH_ENTER_CHECKOUT_ERROR';
export type ActionClearFetchEnterCheckoutError = {
  type: typeof CLEAR_FETCH_ENTER_CHECKOUT_ERROR;
};

export function clearFetchEnterCheckoutError(): ActionClearFetchEnterCheckoutError {
  return {
    type: CLEAR_FETCH_ENTER_CHECKOUT_ERROR,
  };
}

export function sendNewYorkFertilityNeedlePromptCompleted(
  all_items_button_clicked: boolean,
  individual_item_button_clicked: boolean,
) {
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    const { enterCartData } = state.ui.checkoutFlow;
    const require_needle_prescriptions = enterCartData?.needle_prompt?.require_needle_prescriptions || [];
    const needle_prescriptions = enterCartData?.needle_prompt?.needle_prescriptions || [];
    const { items } = getCart(state);

    if (!items) return;

    const originalItemsInTheCart = items.filter(
      (item: { resource_id: number }) =>
        !needle_prescriptions.some((needle_prescription: any) => needle_prescription.id === item.resource_id),
    );

    const needlePrescriptionsAdded = items.filter((item: { resource_id: number }) =>
      needle_prescriptions.some((needle_prescription: any) => needle_prescription.id === item.resource_id),
    );

    originalItemsInTheCart.map((item: ItemKey) => {
      dispatch(
        sendAnalyticsEvent(
          createEvent(
            EVENTS.NEW_YORK_FERTILITY_NEEDLE_PROMPT_COMPLETED,
            {
              prescription_triggers_prompt: require_needle_prescriptions?.some(
                (p: { id: number }) => p.id === item.resource_id,
              ),
              all_items_button_clicked,
              individual_item_button_clicked,
              needle_prescriptions_added: needlePrescriptionsAdded.map((item) => item.resource_id),
            },
            { prescriptionId: item.resource_id },
          ),
        ),
      );
    });
  };
}

export function sendNewYorkFertilityNeedlePromptViewed() {
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    const { enterCartData, enterCartTypes: currentCartTypes } = state.ui.checkoutFlow;
    const require_needle_prescriptions = enterCartData?.needle_prompt?.require_needle_prescriptions || [];
    const needle_prescriptions = enterCartData?.needle_prompt?.needle_prescriptions || [];
    const { items } = getCart(state);

    if (!items) return;

    items.map((item: ItemKey) => {
      dispatch(
        sendAnalyticsEvent(
          createEvent(
            EVENTS.NEW_YORK_FERTILITY_NEEDLE_PROMPT_VIEWED,
            {
              prescription_triggers_prompt: require_needle_prescriptions?.some(
                (p: { id: number }) => p.id === item.resource_id,
              ),
              missing_needles_prompt_shown:
                Array.isArray(currentCartTypes) &&
                currentCartTypes.includes(CheckoutFlowTypeMap.NEEDLE_PROMPT_NO_NEEDLES),
              needle_prescriptions_shown: needle_prescriptions.map((p: { id: number }) => p.id),
            },
            { prescriptionId: item.resource_id },
          ),
        ),
      );
    });
  };
}

export const FETCH_ENTER_CART_LOADING = 'FETCH_ENTER_CART_LOADING';
export type ActionFetchingEnterCart = {
  type: typeof FETCH_ENTER_CART_LOADING;
};

export function fetchingEnterCart(): ActionFetchingEnterCart {
  return {
    type: FETCH_ENTER_CART_LOADING,
  };
}

export const FETCH_ENTER_CART_SUCCEEDED = 'FETCH_ENTER_CART_SUCCEEDED';
export type ActionFetchEnterCartSucceeded = {
  type: typeof FETCH_ENTER_CART_SUCCEEDED;
  payload: EnterCartData;
};

export function fetchEnterCartSucceeded(payload: EnterCartData): ActionFetchEnterCartSucceeded {
  return {
    type: FETCH_ENTER_CART_SUCCEEDED,
    payload,
  };
}

export const FETCH_ENTER_CART_FAILED = 'FETCH_ENTER_CART_FAILED';
export type ActionEnterCartFlowFailed = {
  type: typeof FETCH_ENTER_CART_FAILED;
  payload: APIError;
};

export function fetchEnterCartFailed(error: APIError): ActionEnterCartFlowFailed {
  return {
    type: FETCH_ENTER_CART_FAILED,
    payload: error,
  };
}

export const CLEAR_FETCH_ENTER_CART_ERROR = 'CLEAR_FETCH_ENTER_CART_ERROR';
export type ActionClearFetchEnterCartError = {
  type: typeof CLEAR_FETCH_ENTER_CART_ERROR;
};

export function clearFetchEnterCartError(): ActionClearFetchEnterCartError {
  return {
    type: CLEAR_FETCH_ENTER_CART_ERROR,
  };
}

export const CLEAR_ENTER_CART_TYPES = 'CLEAR_ENTER_CART_TYPES';
export type ActionClearEnterCartTypes = {
  type: typeof CLEAR_ENTER_CART_TYPES;
};

export function clearEnterCartTypes(): ActionClearEnterCartTypes {
  return {
    type: CLEAR_ENTER_CART_TYPES,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export const fetchEnterCart = () => {
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared): Promise<FetchEnterCartResponse> => {
    dispatch(setWasNeedlePromptShown(false));
    dispatch(fetchingEnterCart());
    dispatch(clearEnterCartTypes());
    dispatch(clearFetchEnterCartError());

    const state = getState();
    const { items, address_id } = getOrder(state);
    const data: FetchEnterCartRequest = {
      cart_prescription_ids: items?.map((item) => item.resource_id) || [],
      address_id,
    };

    return get(`/checkout_flow/enter_cart`, data, {
      version: 'v1',
    }).then((json) => {
      if (json.error) {
        dispatch(fetchEnterCartFailed(json.error));
      } else {
        dispatch(fetchEnterCartSucceeded(json.data));
      }

      return json;
    });
  };
};

export const TOGGLE_EDIT_ORDER_WITH_ASSISTANT = 'TOGGLE_EDIT_ORDER_WITH_ASSISTANT';
export type ActionToggleEditOrderWithAssistant = {
  type: typeof TOGGLE_EDIT_ORDER_WITH_ASSISTANT;
};

export function toggleEditOrderWithAssistant(): ActionToggleEditOrderWithAssistant {
  return {
    type: TOGGLE_EDIT_ORDER_WITH_ASSISTANT,
  };
}

export const SET_WAS_NEEDLE_PROMPT_SHOWN = 'SET_WAS_NEEDLE_PROMPT_SHOWN';
export type ActionSetWasNeedlePromptShown = {
  type: typeof SET_WAS_NEEDLE_PROMPT_SHOWN;
  payload: boolean;
};

export function setWasNeedlePromptShown(payload: boolean): ActionSetWasNeedlePromptShown {
  return {
    type: SET_WAS_NEEDLE_PROMPT_SHOWN,
    payload,
  };
}

export const FETCH_DELIVERY_WINDOWS_LOADING = 'FETCH_DELIVERY_WINDOWS_LOADING';
export type ActionFetchDeliveryWindowsLoading = {
  type: typeof FETCH_DELIVERY_WINDOWS_LOADING;
};

export function fetchingDeliveryWindows(): ActionFetchDeliveryWindowsLoading {
  return {
    type: FETCH_DELIVERY_WINDOWS_LOADING,
  };
}

export const FETCH_DELIVERY_WINDOWS_SUCCEEDED = 'FETCH_DELIVERY_WINDOWS_SUCCEEDED';
export type ActionFetchDeliveryWindowsSucceeded = {
  type: typeof FETCH_DELIVERY_WINDOWS_SUCCEEDED;
  payload: FetchAvailableDeliveryWindowsResponseData;
  meta: {
    analytics: {
      eventType: any;
    };
  };
};

export function fetchDeliveryWindowsSucceeded(
  data: FetchAvailableDeliveryWindowsResponseData,
): ActionFetchDeliveryWindowsSucceeded {
  return {
    type: FETCH_DELIVERY_WINDOWS_SUCCEEDED,
    payload: data,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export const FETCH_DELIVERY_WINDOWS_FAILED = 'FETCH_DELIVERY_WINDOWS_FAILED';
export type ActionFetchDeliveryWindowsFailed = {
  type: typeof FETCH_DELIVERY_WINDOWS_FAILED;
  payload: APIError;
  error: true;
  meta: {
    analytics: Record<string, any>;
  };
};

export function fetchDeliveryWindowsFailed(error: APIError): ActionFetchDeliveryWindowsFailed {
  return {
    type: FETCH_DELIVERY_WINDOWS_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export const hasScheduleableDeliveryFeesInCart = (payload: boolean) => ({
  type: 'HAS_SCHEDULEABLE_DELIVERY_FEES_IN_CART' as const,
  payload,
});

export const scheduleableSameDayFee = (payload: AvailableShipmentFee | null) => ({
  type: 'SCHEDULEABLE_SAME_DAY_FEE' as const,
  payload,
});

export const CLEAR_DELIVERY_WINDOWS = 'CLEAR_DELIVERY_WINDOWS';
export type ActionClearDeliveryWindows = {
  type: typeof CLEAR_DELIVERY_WINDOWS;
};

export function clearDeliveryWindows(): ActionClearDeliveryWindows {
  return {
    type: CLEAR_DELIVERY_WINDOWS,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchDeliveryWindows(
  address_id: number | string | undefined,
  date: string | undefined,
  prescription_ids: number[],
  delivery_ids: number[],
) {
  return (dispatch: ReduxDispatch) => {
    if (address_id && address_id !== 'NEW_ADDRESS' && date) {
      const params = {
        address_id,
        date,
        prescription_ids,
        delivery_ids,
      };
      dispatch(fetchingDeliveryWindows());
      return get('/scheduling/delivery_windows', params, { version: 'v1', splitArrayParams: true }).then((json) => {
        if (json.error) {
          dispatch(fetchDeliveryWindowsFailed(json.error));
          return false;
        }

        dispatch(fetchDeliveryWindowsSucceeded(json.data));

        return true;
      });
    }

    return Promise.resolve(false);
  };
}
