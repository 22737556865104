// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  Button,
  Column,
  Description,
  InlineAlert,
  LoadingEmptyState,
  MdSpacing,
  SecondaryPage,
  XlPadding,
  XlSpacing,
} from '@alto/design-system';
import { InsuranceCard } from '@alto/features';
import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { goBack, push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { setEditingInsuranceID, updateOnboardingUser } from '~shared/actions/onboarding';
import { ONBOARD_STATE_INSURANCE_FOUND } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useGetInsurances } from '~shared/queries/query-keys/insurances';
import { useDispatchShared } from '~shared/store';
import type { Insurance as InsuranceType } from '~shared/types';
import { InfoRequestHeader } from './InfoRequestHeader';
import { NavBarLogo } from './NavBarLogo';
import { useQueryInsurancePageContent } from '~web/features/info-request/hooks';
import { useStatusBarColor } from '~web/features/info-request/hooks/useStatusBarColor';

type Props = {
  params: {
    hubReferralID: string;
  };
};

const InsurancePageComponent = (props: Props) => {
  const { hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const dispatch = useDispatchShared();
  const { trackPageView } = useAnalytics();
  const currentPath = window.location.pathname;
  const { insurances, isPending: fetchInsurancesLoading, error: fetchInsurancesError } = useGetInsurances();
  const { isFetching, headerLogoUrl, nextURL } = useQueryInsurancePageContent({
    hubReferralID,
  });

  const handleEditInsurance = (insurance: InsuranceType) => {
    const uploadPhotoURL = currentPath.replace('/insurance/', '/insurance-input-photo/');
    const enterManualURL = currentPath.replace('/insurance/', '/insurance-input-manual/');
    const url = insurance.image_url ? uploadPhotoURL : enterManualURL;
    dispatch(setEditingInsuranceID(insurance.id));
    dispatch(push(url));
  };

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const handleSubmit = () => {
    if (nextURL) {
      dispatch(push(nextURL));
    }
  };

  useStatusBarColor(COLORS.BACKGROUND_COLORS.GREY_LIGHTEST);

  useEffect(() => {
    dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateOnboardingUser({
        onboard_state: ONBOARD_STATE_INSURANCE_FOUND,
      }),
    );

    trackPageView({
      event: EVENTS.INFO_REQUEST__INSURANCE_FOUND_VIEWED,
      params: { origin: '/insurance-input', hubReferralID },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <SecondaryPage
      footerPlacementContext="webScreenWithoutPolicyFooter"
      withoutWebFramingElementHeights
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      dismissIcon="chevronleft"
      onDismiss={handleGoBack}
      NavBarCenterContent={headerLogoUrl ? <NavBarLogo illustrationSrc={headerLogoUrl} /> : undefined}
      HeaderContent={
        <InfoRequestHeader
          title="Insurance"
          subtitle="Review the info below to make sure it's correct."
        />
      }
      buttons={
        !fetchInsurancesError && !fetchInsurancesLoading && !isFetching
          ? [
              <Button
                label="Continue"
                key="continue"
                onPress={handleSubmit}
              />,
            ]
          : []
      }
    >
      {fetchInsurancesLoading ? <LoadingEmptyState /> : null}
      {fetchInsurancesError && !fetchInsurancesLoading ? (
        <>
          <InlineAlert type="error">
            <Description>Looks like we're having some technical difficulty. Please refresh this page.</Description>
          </InlineAlert>
          <XlSpacing />
        </>
      ) : null}
      {!fetchInsurancesLoading && !fetchInsurancesError && (
        <Column>
          {insurances.map((insurance, index) => (
            <Fragment key={`insurance-card-${insurance.id}`}>
              <InsuranceCard
                insurance={insurance}
                onEditInsurance={handleEditInsurance}
              />
              {index !== insurances.length - 1 && <MdSpacing />}
            </Fragment>
          ))}
        </Column>
      )}
    </SecondaryPage>
  );
};

export const InsurancePage = withRouter(InsurancePageComponent);
